@import '~antd/dist/antd.less';

.logo {
  width: auto;
  max-width: 100%;
  height: 100%;
  padding: 10px 15px;
  margin-left: 10px;
}

@primary-color: #002677;@primary-1: #E5F8FB;@error-color: #C40000;@warning-color: #F5B700;@success-color: #007000;@info-color: #E5F8FB;@highlight-color: #C40000;@processing-color: @primary-color;@normal-color: #333333;@body-background: #FBFBFB;@text-color: #333333;@text-color-secondary: #333333;@icon-color-hover: #333333;@heading-color: @primary-color;@text-color-dark: #FFFFFF;@text-color-secondary-dark: #FFFFFF;@font-size-base: 16px;@border-radius-base: 8px;@border-color-base: #DADBDC;@background-color-light: #E9E9E9;@background-color-base: #DADBDC;@disabled-color: fade(#333333, 35%);@disabled-color-dark: fade(#FFFFFF, 35%);@layout-header-background: #FFFFFF;@layout-header-padding: 0;@input-placeholder-color: fade(#333333, 50%);@table-expanded-row-bg: #E9E9E9;@card-padding-base: 16px;@tabs-vertical-margin: 8px 0px 0px 0px;@tabs-vertical-padding: 8px 16px;@tooltip-bg: @primary-color;@btn-border-radius-base: 16px;@link-color: #196ECF;@layout-body-background: #FFFFFF;@btn-primary-bg: @primary-color;@alert-info-icon-color: @primary-color;@alert-with-description-icon-size: 18px;